/* 
  @file

  unieducar_2019_site, by @onaSousa
  https://bitbucket.org/7Links/unieducar.7links.info/src/master/

  The major stylesheet for this theme

  This starter is totally based on:
  * SMACSS by Jonathan Snook http://smacss.com/
  * OOCSS by Nicole Sullivan http://oocss.org/
  * BEM CSS Methology : http://bem.info/method/
  * CSS Guidelines by Harry Roberts : https://github.com/csswizardry/CSS-Guidelines
  
  Thanks for:
  * Pattern Lab (Atomic Design) 
  * CodyHouse's Framework

  Warnings:
  ** Archive overwrite Bootstrap
*/
/*------------------------------------*\
  Settings...............Set tools and configurations for the contents
\*------------------------------------*/
:root {
  --cl-gray-100: #f9f9fa;
  --cl-gray-200: #eeeef1;
  --cl-gray-300: #d7d7db;
  --cl-gray-400: #b2b2b3;
  --cl-gray-500: #737373;
  --cl-gray-600: #4a4a4f;
  --cl-gray-700: #38383d;
  --cl-gray-800: #29292e;
  --cl-gray-900: #0c0c0d;
  --cl-blue: #0f82e6;
  --cl-indigo: #6610f2;
  --cl-purple: #6f42c1;
  --cl-pink: #e83e8c;
  --cl-red: #dc3545;
  --cl-orange: #fd7e14;
  --cl-yellow: #ffc107;
  --cl-green: #28a745;
  --cl-teal: #20c997;
  --cl-cyan: #17a2b8;
  --cl-white: #fff;
  --cl-gray: #4a4a4f;
  --cl-gray-dark: #29292e;
  --cl-primary: #094d88;
  --cl-secondary: #6a8c28;
  --cl-success: #13bd00;
  --cl-info: #0a85ff;
  --cl-warning: #d66f00;
  --cl-danger: #d60020;
  --cl-light: #f9f9fa;
  --cl-dark: #29292e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

:root, body {
  --fz-uul:    4.5rem;
  --fz-ul:     4rem;
  --fz-hhhl:   3rem;
  --fz-hhl:    2.75rem;
  --fz-hl:     2.25rem;
  --fz-xxl:    2rem;
  --fz-xl:     1.75rem;
  --fz-l:      1.5rem;
  --fz-xxm:    1.25rem;
  --fz-xm:     1.125rem;
  --fz-m:      1rem;
  --fz-s:      0.875rem;
  --fz-xs:     0.705rem;
  --fz-xxs:    0.625rem;
  --sp-quarter:   0.25rem;
  --sp-six:       0.375rem;
  --sp-half:      0.5rem;
  --sp-twelve:    0.75rem;
  --sp-unit:      1rem;
  --sp-unit-half: 1.5rem;
  --sp-double:    2rem;
  --sp-fourty:    2.5rem;
  --sp-triple:    3rem;
  --sp-quad:      4rem;
  --sp-eighty:    5rem;
}

/*------------------------------------*\
  Layouts................Macro arrangement of a web page, including any grid systems
\*------------------------------------*/
/*------------------------------------*\
  Layouts................Header
\*------------------------------------*/
.c-logo {
  display: block;
}

.c-logo__img {
  height: 30px;
}

@media screen and (max-width: 991px) {
  .c-logo {
    overflow: hidden;
    width: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header-links-teacher {
  font-size: var(--fz-s);
  font-weight: bold;
  line-height: 1;
  max-width: 75px;
}

.c-link-login span,
a.c-link-register {
  display: none;
}

.c-header-links {
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.c-header-links li + li {
  margin-left: var(--sp-half);
}

.c-header-links__link {
  display: none;
}

.c-header-links__item {
  list-style: none;
}

.c-link-ada.btn {
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1;
}

.c-link-ada svg {
  display: none;
}

.c-link-ada__tit {
  color: var(--cl-gray-500);
  font-size: var(--fz-xs);
  font-weight: 600;
  margin-bottom: var(--sp-quarter);
}

.c-link-ada__name {
  display: block;
  overflow: hidden;
  font-size: 15px;
  white-space: nowrap;
  max-width: 120px;
}

.c-link-ada:hover .c-link-ada__tit {
  color: var(--cl-white);
}

@media screen and (max-width: 991px) {
  .c-link-ada svg {
    display: block;
  }
  .c-link-ada__name {
    display: none;
  }
  .c-link-ada > .c-link-ada__tit {
    display: none !important;
  }
}

.c-user-acess {
  margin-left: var(--sp-unit-half);
}

.o-region-header {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.o-header {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px 0;
  background-color: var(--cl-white);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
}

.o-header img {
  max-width: 100vw;
  height: auto;
}

.o-header .container {
  position: relative;
  display: flex;
  align-items: center;
}

.header-links-cart {
  display: block;
}

@media screen and (max-width: 991px) {
  a.c-link-login {
    height: auto;
    padding: var(--sp-half);
    border: 0;
    margin-left: var(--sp-unit-half);
  }
}

@media screen and (min-width: 993px) {
  #unieducar-texto,
  #unieducar-slogan {
    display: block;
  }
  .c-logo {
    margin-right: var(--sp-double);
  }
  .o-region-header {
    margin-left: 0;
    margin-right: var(--sp-unit);
  }
  .header-links-suport {
    display: block;
  }
  .c-link-login,
  a.c-link-register {
    display: inline-flex;
    align-items: center;
  }
  .c-link-login span {
    display: block;
  }
  .c-link-login svg {
    display: none;
  }
  .c-user-acess {
    margin-left: auto;
  }
  .o-header {
    height: 65px;
  }
}

@media screen and (min-width: 1201px) {
  .c-logo__img {
    height: 35px;
  }
  .c-header-links__link {
    display: block;
  }
  .c-header-links li + li {
    margin-left: var(--sp-unit);
  }
  .o-header .container {
    justify-content: space-between;
  }
}

/*------------------------------------*\
  Components.............Discrete, reusable UI elements
\*------------------------------------*/
/*------------------------------------*\
  Components.............Menu cursos
\*------------------------------------*/
.c-nav-primary {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
}

.c-nav-primary li {
  list-style: none !important;
  padding: 0 !important;
}

.c-nav-primary .c-nav-primary__menu {
  padding: var(--sp-twelve) 0;
}

.c-nav-primary > .c-nav-primary__item {
  margin: 0 0 var(--sp-unit-half) 0;
}

.c-nav-primary .c-nav-primary__subitem {
  margin: 0 0 var(--sp-six);
}

.c-nav-primary__link {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: var(--fz-xm);
  line-height: 1;
}

.c-nav-primary__link::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: var(--sp-quarter);
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjUwNzggMi45MTUwM0MxMS40NDQgMy44NTg3NSAxMC4zODU5IDQuODAyNDcgOS4zMjIwNCA1Ljc1MTkxQzcuNjQwNSA3LjI1MDQyIDUuOTU4OTYgOC43NDg5NCA0LjI3MTcgMTAuMjQ3NUMzLjg4Mjc4IDEwLjU5MDYgMy40OTk1NyAxMC45MzM4IDMuMTEwNjQgMTEuMjgyN0MzLjExMDY0IDEwLjQ3NjIgMy4xMTA2NCA5LjY2NDA2IDMuMTEwNjQgOC44NTc2MUM0LjE2ODc1IDkuNzg0MTcgNS4yMjY4NiAxMC43MTA3IDYuMjg0OTcgMTEuNjM3M0M3Ljk3MjIzIDEzLjExMjkgOS42NTk0OSAxNC41OTQzIDExLjM0MSAxNi4wNjk5QzExLjczIDE2LjQwNzQgMTIuMTEzMiAxNi43NDQ4IDEyLjUwMjEgMTcuMDg4QzEzLjE3NyAxNy42ODI4IDEzLjE1NDEgMTguOTE4MiAxMi41MDIxIDE5LjUxMzFDMTEuNzgxNCAyMC4xNzY1IDEwLjc5NzcgMjAuMTQ3OSAxMC4wNzcgMTkuNTEzMUM5LjAxODkgMTguNTg2NSA3Ljk2MDc5IDE3LjY1OTkgNi45MDI2OCAxNi43MzM0QzUuMjE1NDMgMTUuMjU3NyAzLjUyODE3IDEzLjc3NjQgMS44NDY2MyAxMi4zMDA4QzEuNDU3NyAxMS45NjMzIDEuMDc0NDkgMTEuNjI1OSAwLjY4NTU2NyAxMS4yODI3QzAuMDE2MzgzMiAxMC42OTM2IDAuMDI3ODIyNSA5LjQ0MSAwLjY4NTU2NyA4Ljg1NzYxQzEuNzQ5NCA3LjkxMzg5IDIuODA3NTEgNi45NzAxNyAzLjg3MTM0IDYuMDIwNzNDNS41NTI4OCA0LjUyMjIxIDcuMjM0NDEgMy4wMjM3IDguOTIxNjcgMS41MjUxOUM5LjMxMDYgMS4xODIwMiA5LjY5MzgxIDAuODM4ODQ2IDEwLjA4MjcgMC40ODk5NTZDMTAuNzUxOSAtMC4xMDQ4NzQgMTEuODU1OCAtMC4yMTkyNjQgMTIuNTA3OCAwLjQ4OTk1NkMxMy4xMDI2IDEuMTQxOTggMTMuMjIyNyAyLjI4MDE2IDEyLjUwNzggMi45MTUwM1oiIGZpbGw9IiMwOTREODgiLz4KPC9zdmc+Cg==") center no-repeat;
  background-size: 50% auto;
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.c-nav-primary__link:hover {
  text-decoration: none;
}

.c-nav-primary__menu {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-nav-primary__item.leaf .c-nav-primary__link::before {
  background: none;
}

.c-nav-primary__item.is-active .c-nav-primary__menu {
  display: block;
}

.c-nav-primary__item.is-active .c-nav-primary__link::before {
  transform: rotate(270deg);
}

.c-nav-primary__subitem a {
  font-size: var(--fz-xm);
}

@media screen and (min-width: 481px) {
  .c-nav-primary__item.is-active .c-nav-primary__menu {
    display: flex;
  }
  .c-nav-primary__subitem {
    width: 48%;
  }
}

@media screen and (min-width: 993px) {
  .c-nav-primary__subitem {
    width: 30%;
  }
  .c-nav-primary__link {
    font-size: var(--fz-l);
  }
}

.c-cats-highlight {
  overflow-x: auto;
  padding: var(--sp-twelve) 0;
}

.c-cats-highlight__txt {
  font-style: normal;
}

.c-cats-highlight__img {
  width: 50px;
  height: 20px;
  margin-right: var(--sp-half);
}

.c-cats-highlight__item {
  display: flex;
  align-items: center;
  color: var(--cl-secondary);
  text-transform: uppercase;
  font-size: var(--fz-s);
  font-weight: bold;
  line-height: 1.3;
  min-width: 140px;
}

@media screen and (min-width: 768px) {
  .c-cats-highlight__item {
    font-size: var(--fz-m);
  }
}

@media screen and (min-width: 993px) {
  .c-cats-highlight {
    max-width: 50%;
  }
}

@media screen and (min-width: 1441px) {
  .c-cats-highlight {
    max-width: 25%;
  }
}

/*------------------------------------*\
  Components.............Search
  Form search block in the header
  All pages exibition
\*------------------------------------*/
#edit-palavra-wrapper .description {
  display: none;
}

.c-search .views-widget-filter-populate,
.c-search .views-submit-button {
  padding: 0;
}

.c-search .views-widget-filter-populate {
  width: 100%;
}

.c-search .views-exposed-widgets {
  display: flex;
  margin: 0;
}

.c-search form {
  overflow: hidden;
  width: 100%;
  background-color: var(--cl-gray-200);
  border-radius: 0.2rem;
}

.c-search .form-text {
  height: 45px;
  padding-right: 0;
  border: 0;
  margin-top: 0;
  background-color: transparent;
}

.c-search .form-text:focus {
  background-color: transparent;
}

.c-search .form-submit {
  text-indent: 100%;
  color: transparent;
  width: 45px;
  height: 45px;
  background: transparent url("../images/icon-search.svg") center no-repeat;
}

.c-search .form-submit:hover,
.c-search .form-submit:focus {
  background-color: transparent;
}

.c-search .form-submit {
  padding: 0;
  border: 0;
  margin: 0 !important;
}

.c-search__btn {
  display: flex;
}

.c-search__img {
  width: 22px;
  height: 22px;
}

.c-search .c-search__close span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fz-xl);
  font-weight: bold;
  color: var(--cl-danger);
  width: var(--sp-double);
}

.c-search__main {
  display: none;
}

.c-search__main.is-open {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  width: 100vw;
  height: 100vh;
  padding: var(--sp-quarter) 2px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
}

.c-search__main.is-open .form-text,
.c-search__main.is-open .form-submit,
.c-search__main.is-open .c-search__close span {
  height: 55px;
}

@media screen and (min-width: 993px) {
  .c-search {
    margin-right: var(--sp-unit);
  }
  .c-search__main {
    display: block;
  }
  .c-search__btn, .c-search__close {
    display: none;
  }
}

@media screen and (min-width: 1201px) {
  .c-search {
    margin-right: var(--sp-double);
  }
  .c-search .views-exposed-widgets {
    width: 405px;
  }
}

/*------------------------------------*\
  Components.............Navigation
\*------------------------------------*/
.c-block-block-15 br {
  display: none;
}

.c-block-block-15 a {
  display: block;
}

.c-block-block-15 img {
  width: 100%;
  height: auto;
}

.c-navigation {
  margin-right: var(--sp-unit);
}

.c-navigation__img {
  width: 22px;
  height: 22px;
  margin-right: var(--sp-six);
}

.c-navigation__txt {
  display: none;
}

.c-navigation__btn {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.c-navigation__btn:hover {
  text-decoration: none;
}

@media screen and (min-width: 993px) {
  .c-navigation {
    margin-right: var(--sp-unit-half);
  }
  .c-navigation__txt {
    display: block;
  }
  .c-navigation__img {
    width: auto;
    height: auto;
    margin-right: var(--sp-six);
  }
}

@media screen and (min-width: 1201px) {
  .c-navigation .c-navigation {
    margin-right: var(--sp-double);
  }
}

@media screen and (min-width: 1441px) {
  .c-navigation .c-block-menu-menu-header-menu-2 {
    max-width: 1300px;
    margin: 0 auto;
  }
}

div.c-navigation__dialog {
  padding: 0;
}

.c-navigation__dialog .easy-dialog__logo {
  display: table;
  margin: 0 auto var(--sp-unit-half);
  height: 40px;
}

.c-navigation__dialog .easy-dialog__container {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  max-width: initial;
  border-radius: 0;
}

.c-navigation__dialog .easy-dialog__content {
  height: auto;
  margin-bottom: auto;
}

.c-navigation__dialog .easy-dialog__footer {
  background-color: var(--cl-gray-100);
  margin: 0 -16px -16px;
}

@media screen and (min-width: 993px) {
  .c-navigation__dialog .easy-dialog__footer {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1201px) {
  .c-navigation__dialog .easy-dialog__title {
    font-size: var(--fz-hl);
  }
}
