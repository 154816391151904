
// Helpers
$i:           !important;
$t:           transparent;
$n:           none;
$in:          initial;
$ih:          inherit;
$cc:          currentColor;
$imgPath:     '../images';

$breakpoints: (
  xsmall: 480px,
  small: 768px,
  medium: 992px,
  mindesktop: 1024px,
  large: 1200px, 
  xlarge: 1440px
);

$grad-blue: linear-gradient(90deg, #0f82e6 0%, #0e6ec1 47.36%);
$grad-green: linear-gradient(90deg, #5c8d4a 0%, #8ec43b 100%);
$grad-purple: linear-gradient(0deg, #410D66 0%, #8A0091 100%);
$grad-pink: linear-gradient(14deg,  #bf2885 0%,#ffcb75 100%,#6e7ff3 100%);


//
// Bootstrap replace
// Replace styles 
// 

// Color system

$gray-100: #f9f9fa;
$gray-200: #eeeef1;
$gray-300: #d7d7db;
$gray-400: #b2b2b3;
$gray-500: #737373;
$gray-600: #4a4a4f;
$gray-700: #38383d;
$gray-800: #29292e;
$gray-900: #0c0c0d;

$blue: #0f82e6;

$primary:       #094d88;
$secondary:     #6a8c28;
$success:       #13bd00;
$info:          #0a85ff;
$warning:       #d66f00;
$danger:        #d60020;

// Body
//
// Settings for the `<body>` element.

$body-color:    #575550;

// Typography

$opensans: 'Open Sans', Tahoma, 'DejaVu Sans', sans-serif;
$font-family-base: $opensans;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$font-weight-semibold: 600;
$font-weight-bolder: 800;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px
);

$input-height: 45px;
$input-border-color: $gray-300;

$pagination-border-color: rgba(0,0,0, .07);
$pagination-hover-bg: $gray-100;

$badge-font-size: 12px;
$badge-font-weight: 600;
$badge-padding-y: 6px;
$badge-padding-x: 8px;