/*------------------------------------*\
  Components.............Search
  Form search block in the header
  All pages exibition
\*------------------------------------*/

#edit-palavra-wrapper .description { display: none; }

.c-search .views-widget-filter-populate, 
.c-search .views-submit-button { padding: 0; }

.c-search .views-widget-filter-populate { width: 100%; }

.c-search .views-exposed-widgets {
  display: flex;
  margin: 0;
}


.c-search {
  
  form {
    overflow: hidden;
    width: 100%;
    background-color: var(--cl-gray-200);
    border-radius: $border-radius-sm;
  }

  .form-text {
    height: 45px;
    padding-right: 0;
    border: 0;
    margin-top: 0;
    background-color: $t;
  }
  .form-text:focus { background-color: $t; }

  .form-submit {
    text-indent: 100%;
    color: $t;
    width: 45px;
    height: 45px;
    background: $t url('#{$imgPath}/icon-search.svg') center no-repeat;
  }
  .form-submit:hover, 
  .form-submit:focus { background-color: $t; }

  .form-submit {
    padding: 0;
    border: 0;
    margin: 0 $i;
  }

  &__btn { display: flex; }
  &__img { @include square(22px); }

  .c-search__close span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fz-xl);
    font-weight: bold;
    color: var(--cl-danger);
    width: var(--sp-double);
  }

  &__main {
    display: none;

    // RWD
    &.is-open {
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      @include square(100vw, 100vh);
      padding: var(--sp-quarter) 2px;
      background-color: rgba($black, .9);
      z-index: 50;
      position: fixed;
      top: 0;
      left: 0;
  
      .form-text, 
      .form-submit, 
      .c-search__close span { height: 55px; }
    }
  }


  @include media('screen', '>medium') {
    margin-right: var(--sp-unit);

    &__main { display: block; }
    
    &__btn, 
    &__close { display: none; }
  }

  @include media('screen', '>large') {
    margin-right: var(--sp-double);

    .views-exposed-widgets { width: 405px; }
  }
}