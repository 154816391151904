:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $grays {
    --cl-gray-#{$color}: #{$value};
  }
  
  @each $color, $value in $colors {
    --cl-#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --cl-#{$color}: #{$value};
  }

  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};
}

// variables theme custom - *Not Bootstrap
:root, body {
  // font-sizes
  --fz-uul:    4.5rem;   // 72px
  --fz-ul:     4rem;     // 64px
  --fz-hhhl:   3rem;     // 48px
  --fz-hhl:    2.75rem;  // 44px
  --fz-hl:     2.25rem;  // 36px
  --fz-xxl:    2rem;     // 32px
  --fz-xl:     1.75rem;  // 28px
  --fz-l:      1.5rem;   // 24px
  --fz-xxm:    1.25rem;  // 20px
  --fz-xm:     1.125rem; // 18px
  --fz-m:      1rem;
  --fz-s:      0.875rem; // 14px
  --fz-xs:     0.705rem;  // 12px
  --fz-xxs:    0.625rem; // 10px
  // spaces
  --sp-quarter:   0.25rem;  // 4px
  --sp-six:       0.375rem; // 6px
  --sp-half:      0.5rem;   // 8px
  --sp-twelve:    0.75rem;  // 12px
  --sp-unit:      1rem;     // 16px
  --sp-unit-half: 1.5rem;   // 24px
  --sp-double:    2rem;     // 32px
  --sp-fourty:    2.5rem;   // 40px
  --sp-triple:    3rem;     // 48px
  --sp-quad:      4rem;     // 64px
  --sp-eighty:    5rem;     // 80px
}