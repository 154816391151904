// edit font rendering -> tip: use for light text on dark backgrounds
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// crop top space on text elements - caused by line height
@mixin lhCrop($line-height, $capital-letter: 1) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
}

// CSS triangle
@mixin triangle ($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if( $direction == left ) {
    border-right-color: $color;
  } @else if( $direction == right ) {
    border-left-color: $color;
  } @else if( $direction == down ) {
    border-top-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

// reset user agent style
@mixin reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

// --------------------------------

// Color functions

// --------------------------------

// define color css variables
@mixin defineColorHSL($color, $hue, $saturation, $lightness){
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");#{$color}-h: #{$hue};#{$color}-s: #{$saturation};#{$color}-l: #{$lightness};
}

// return css color variable with different opacity value
@function alpha($color, $opacity){
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsla($color-h, $color-s, $color-l, $opacity);
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}



@mixin no-link {
  cursor: $cursor-disabled;
  pointer-events: none; // Future-proof disabling of clicks
}

// Truncate text
// If the text is longer than one line, it will be truncated and end with an ellipsis(...).
@mixin truncate-text( $tru-w ) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $tru-w;
}

@mixin mobile-scroll-horizontal {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

@mixin clear-list {
  list-style: none;
  padding: 0;
}

//*----------------------------------*\
//  SIZE
//\*----------------------------------*/

/// Size - Set propertys width and height
/// @author Hugo Giraudel
/// @group Tools
/// @param {Number} $width - Property width css
/// @param {Number} $height [$width] - Default value width, but it's possible use diferent height
/// @example scss - basic usage
///   .square {
///     @include size(50px);
///   }
/// @output Output from the example
///   .square {
///     width: 50px;
///     height: 50px;
///   }

@mixin square($width, $height: $width) {
  width: $width;
  height: $height;
}

//*----------------------------------*\
//  HOVER
//\*----------------------------------*/

/// Hover - Create pseduo-classes in link: `:hover`, `:active`, and `:focus`.
/// @author Hugo Giraudel
/// @group Tools
/// @content propertys in `:hover`, `:active`, and `:focus` link.
/// @example scss - basic usage
///   .simple-link {
///     ...
///     @include hover {
///       ...
///     }
///   }

@mixin hover {
  &:hover,
  &:active,
  &:focus { @content; }
}

//*----------------------------------*\
//  CENTERER
//\*----------------------------------*/

/// Centerer - Position elements centered in directions especific or all
/// @author CSS-Tricks
/// @link https://css-tricks.com/snippets/sass/centering-mixin/ In the comments have a optimization this mixin
/// @group Tools
/// @param {String} $pos [both] - Default functions is centered in all directions, but is possible choice side
/// @example scss - basic usage
///   .centered {
///     @include centerer();
///   }
/// @output Output from the example
///   .centered {
///     position: absolute;
///     top: 50%;
///     left: 50%;
///     transform: translate(-50%, -50%);
///   }

@mixin centerer($pos:both) {
  position: absolute;

  @if ($pos==both) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  } @else if ($pos==top) {
    left: 50%;
    transform: translate(-50%, 0);

  } @else if ($pos==left) {
    top: 50%;
    transform: translate(0, -50%);

  } @else if ($pos==right) {
    top: 50%;
    right:0;
    transform: translate(0, -50%);

  } @else if ($pos==bottom) {
    bottom: 0;
    left:50%;
    transform: translate(-50%, 0);
  }
}

// 
// customs theme
// 

@mixin videos-bg {
  background-color: $primary;
}
@mixin videos-rwd {
  width: 100vw;
  margin: 24px -15px -24px;
}
@mixin videos-width {
  width: 100%;
}
@mixin videos-prods-height {
  max-height: 280px;
}