/*------------------------------------*\
  Components.............Menu cursos
\*------------------------------------*/

.c-nav-primary {
  padding: 0 $i;
  margin: 0 $i;
  position: relative;

  li {
    list-style: none $i;
    padding: 0 $i;
  }

  & &__menu { padding: var(--sp-twelve) 0; }
  
  & > &__item { margin: 0 0 var(--sp-unit-half) 0; }

  & &__subitem { margin: 0 0 var(--sp-six); }
  
  &__link {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: var(--fz-xm);
    line-height: 1;

    &::before {
      content: '';
      display: block;
      @include square(12px);
      margin-right: var(--sp-quarter);
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjUwNzggMi45MTUwM0MxMS40NDQgMy44NTg3NSAxMC4zODU5IDQuODAyNDcgOS4zMjIwNCA1Ljc1MTkxQzcuNjQwNSA3LjI1MDQyIDUuOTU4OTYgOC43NDg5NCA0LjI3MTcgMTAuMjQ3NUMzLjg4Mjc4IDEwLjU5MDYgMy40OTk1NyAxMC45MzM4IDMuMTEwNjQgMTEuMjgyN0MzLjExMDY0IDEwLjQ3NjIgMy4xMTA2NCA5LjY2NDA2IDMuMTEwNjQgOC44NTc2MUM0LjE2ODc1IDkuNzg0MTcgNS4yMjY4NiAxMC43MTA3IDYuMjg0OTcgMTEuNjM3M0M3Ljk3MjIzIDEzLjExMjkgOS42NTk0OSAxNC41OTQzIDExLjM0MSAxNi4wNjk5QzExLjczIDE2LjQwNzQgMTIuMTEzMiAxNi43NDQ4IDEyLjUwMjEgMTcuMDg4QzEzLjE3NyAxNy42ODI4IDEzLjE1NDEgMTguOTE4MiAxMi41MDIxIDE5LjUxMzFDMTEuNzgxNCAyMC4xNzY1IDEwLjc5NzcgMjAuMTQ3OSAxMC4wNzcgMTkuNTEzMUM5LjAxODkgMTguNTg2NSA3Ljk2MDc5IDE3LjY1OTkgNi45MDI2OCAxNi43MzM0QzUuMjE1NDMgMTUuMjU3NyAzLjUyODE3IDEzLjc3NjQgMS44NDY2MyAxMi4zMDA4QzEuNDU3NyAxMS45NjMzIDEuMDc0NDkgMTEuNjI1OSAwLjY4NTU2NyAxMS4yODI3QzAuMDE2MzgzMiAxMC42OTM2IDAuMDI3ODIyNSA5LjQ0MSAwLjY4NTU2NyA4Ljg1NzYxQzEuNzQ5NCA3LjkxMzg5IDIuODA3NTEgNi45NzAxNyAzLjg3MTM0IDYuMDIwNzNDNS41NTI4OCA0LjUyMjIxIDcuMjM0NDEgMy4wMjM3IDguOTIxNjcgMS41MjUxOUM5LjMxMDYgMS4xODIwMiA5LjY5MzgxIDAuODM4ODQ2IDEwLjA4MjcgMC40ODk5NTZDMTAuNzUxOSAtMC4xMDQ4NzQgMTEuODU1OCAtMC4yMTkyNjQgMTIuNTA3OCAwLjQ4OTk1NkMxMy4xMDI2IDEuMTQxOTggMTMuMjIyNyAyLjI4MDE2IDEyLjUwNzggMi45MTUwM1oiIGZpbGw9IiMwOTREODgiLz4KPC9zdmc+Cg==") center no-repeat;
      background-size: 50% auto;
      transform: rotate(180deg);
      transition: $transition-base;
    }

    @include hover {
      text-decoration: none;
    }
  }

  &__menu {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    // padding: var(--sp-unit) 0;
    // position: absolute;
  }

  &__item.leaf .c-nav-primary__link::before { background: none; }

  &__item.is-active .c-nav-primary__menu { display: block; }

  &__item.is-active .c-nav-primary__link::before { transform: rotate(270deg) }

  &__subitem {
    a { font-size: var(--fz-xm); }
  }


  @include media('screen', '>xsmall') {
    &__item.is-active .c-nav-primary__menu { display: flex; }

    &__subitem { width: 48%; }
  }

  @include media('screen', '>medium') {
    // display: flex;

    // &__menu { display: flex; }
    // &__item:first-child &__menu { display: flex; }
    
    // & > &__item { margin: 0 var(--sp-unit-half) 0 0; }

    &__subitem { width: 30%; }

    &__link { font-size: var(--fz-l); }
  }
}

.c-cats-highlight {
  overflow-x: auto;
  padding: var(--sp-twelve) 0;

  &__txt {
    font-style: normal;
  }

  &__img {
    @include square(50px, 20px);
    margin-right: var(--sp-half);
  }

  &__item {
    display: flex;
    align-items: center;
    color: var(--cl-secondary);
    text-transform: uppercase;
    font-size: var(--fz-s);
    font-weight: bold;
    line-height: 1.3;
    min-width: 140px;
  }

  @include media('screen', '>=small') {
    &__item { font-size: var(--fz-m); }
  }

  @include media('screen', '>medium') {
    max-width: 50%;
  }

  @include media('screen', '>xlarge') {
    max-width: 25%;
  }
}



// @include hover {
//   color: var(--cl-blue);
//   text-decoration: none;

//   &__menu {
//     display: flex;
//     background-color: #025;
//   }
// }

