/*------------------------------------*\
  Components.............Navigation
\*------------------------------------*/

// .c-block-block-18 { // dev
.c-block-block-15 { // prod
  br { display: none; }
  a { display: block; }
  img {
    width: 100%;
    height: auto;
  }
}

.c-navigation {
  margin-right: var(--sp-unit);

  &__img {
    @include square(22px);
    margin-right: var(--sp-six);
  }

  &__txt { display: none; }

  &__btn {
    display: flex;
    align-items: center;
    font-weight: bold;

    @include hover {
      text-decoration: none;
    }
  }

  @include media('screen', '>medium') {
    margin-right: var(--sp-unit-half);

    &__txt { display: block; }

    &__img {
      @include square(auto);
      margin-right: var(--sp-six);
    }
  }

  @include media('screen', '>large') {
    .c-navigation { margin-right: var(--sp-double); }
  }

  @include media('screen', '>xlarge') {
    .c-block-menu-menu-header-menu-2 {
      max-width: 1300px;
      margin: 0 auto;
    }
  }
}

// dialog
div.c-navigation__dialog { padding: 0; }
.c-navigation__dialog {
  
  .easy-dialog__logo {
    display: table;
    margin: 0 auto var(--sp-unit-half);
    height: 40px;
  }

  // .easy-dialog__title {
  //   font-size: var(--fz-l);
  // }

  .easy-dialog__container {
    overflow-y: auto;
    @include square(100%);
    max-width: $in;
    border-radius: 0;
  }

  .easy-dialog__content {
    height: auto;
    margin-bottom: auto;
  }

  .easy-dialog__footer {
    background-color: var(--cl-gray-100);
    margin: 0 -16px -16px;
  }

  // @include media('screen', '>medium') {
  //   .easy-dialog__logo { margin: var(--sp-twelve) auto; }
  // }

  @include media('screen', '>medium') {
    .easy-dialog__footer {
      display: flex;
      justify-content: space-between;
    }
  }

  @include media('screen', '>large') {
    .easy-dialog__title { font-size: var(--fz-hl); } 
  }
}