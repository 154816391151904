/* 
  @file

  unieducar_2019_site, by @onaSousa
  https://bitbucket.org/7Links/unieducar.7links.info/src/master/

  The major stylesheet for this theme

  This starter is totally based on:
  * SMACSS by Jonathan Snook http://smacss.com/
  * OOCSS by Nicole Sullivan http://oocss.org/
  * BEM CSS Methology : http://bem.info/method/
  * CSS Guidelines by Harry Roberts : https://github.com/csswizardry/CSS-Guidelines
  
  Thanks for:
  * Pattern Lab (Atomic Design) 
  * CodyHouse's Framework

  Warnings:
  ** Archive overwrite Bootstrap
*/

/*------------------------------------*\
  Settings...............Set tools and configurations for the contents
\*------------------------------------*/
@import "../node_modules/include-media/dist/_include-media";
@import '../node_modules/jeet/scss/index';
@import 'settings/mixins';
@import 'settings/variables';
@import 'settings/helper-gradient-angle';
@import 'settings/linear-gradient';
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "settings/root"; // **
/*------------------------------------*\
  Layouts................Macro arrangement of a web page, including any grid systems
\*------------------------------------*/
@import 'layouts/header';
/*------------------------------------*\
  Components.............Discrete, reusable UI elements
\*------------------------------------*/
@import 'components/menu-cursos';
@import 'components/search';
@import 'components/navigation';
