/*------------------------------------*\
  Layouts................Header
\*------------------------------------*/

.c-logo {
  display: block;
  
  &__img { height: 30px; }

  @include media('screen', '<medium') {
    overflow: hidden;
    width: 45px;
    @include centerer;
  }
}

.header-links-teacher {
  font-size: var(--fz-s);
  font-weight: bold;
  line-height: 1;
  max-width: 75px;
}

.c-link-login span, 
a.c-link-register { display: none; }


// 
// blocks
// 

.c-header-links {
  padding: 0 $i;
  display: flex;
  align-items: center;

  li + li { margin-left: var(--sp-half); }

  &__link { display: none; }
  &__item { list-style: none; }
}

.c-link-ada.btn {
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1;
}
.c-link-ada {
  svg { display: none; }

  &__tit {
    color: var(--cl-gray-500);
    font-size: var(--fz-xs);
    font-weight: 600;
    margin-bottom: var(--sp-quarter);
  }

  &__name {
    display: block;
    overflow: hidden;
    font-size: 15px;
    white-space: nowrap;
    max-width: 120px;
  }

  &:hover &__tit { color: var(--cl-white); }

  @include media('screen', '<medium') {
    svg { display: block; }
    &__name { display: none; }

    & > &__tit { display: none $i; }
  }
}

.c-user-acess { margin-left: var(--sp-unit-half); }


// 
// regions
// 

.o-region-header {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.o-header {
  display: flex;
  @include square(100%, 50px);
  padding: 10px 0;
  background-color: var(--cl-white);
  box-shadow: 0 0 6px rgba(0, 0, 0, .1);
  position: fixed;
  top: 0;
  left: 0;

  img {
    max-width: 100vw;
    height: auto;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
  }
}


// RWD
.header-links-cart { display: block; }

@include media('screen', '<medium') {
  a.c-link-login {
    height: auto;
    padding: var(--sp-half);
    border: 0;
    margin-left: var(--sp-unit-half);
  }
}
// RWD

@include media('screen', '>medium') {
  #unieducar-texto, 
  #unieducar-slogan { display: block; }

  .c-logo { margin-right: var(--sp-double); }

  .o-region-header {
    margin-left: 0;
    margin-right: var(--sp-unit);
  }

  .header-links-suport { display: block; }
  
  .c-link-login, 
  a.c-link-register {
    display: inline-flex;
    align-items: center;
  }

  .c-link-login span { display: block; }
  .c-link-login svg { display: none; }

  .c-user-acess { margin-left: auto; }

  .o-header { height: 65px; }
}

@include media('screen', '>large') {
  .c-logo__img { height: 35px; }

  .c-header-links__link { display: block; }

  .c-header-links li + li { margin-left: var(--sp-unit); }

  .o-header .container { justify-content: space-between; }
}
